"use client";

import Icon from "@atoms/icon/Icon";
import { cn, tw } from "@utils/tailwind";
import Link, { LinkProps } from "next/link";
import { usePathname } from "next/navigation";
import {
  buildBreadcrumbs,
  formatTitle as formatBreadcrumb,
} from "./Breadcrumbs.helpers";
import { BreadcrumbsProps } from "./Breadcrumbs.types";

const listElementStyles = tw`flex items-center gap-x-2 text-nowrap last-of-type:overflow-hidden`;
const iconStyles = tw`min-w-1 fill-current`;
const lastLinkStyles = tw`w-full min-w-0 flex-1 truncate`;

const Breadcrumbs = ({
  parts: customBreadcrumbs,
  className,
  theme,
}: BreadcrumbsProps) => {
  const pathname = usePathname();
  const breadcrumbs = customBreadcrumbs ?? buildBreadcrumbs(pathname);

  const navStyles = cn(
    "min-w-0 text-sm font-medium",
    !theme && "text-grey-scale-mid darkGreen:text-blue-light",
    theme === "Light" && "text-grey-scale-mid",
    theme === "Dark" && "text-blue-light",
    className
  );

  return (
    <nav className={navStyles} aria-label="breadcrumbs">
      <ul className="relative inline-flex max-w-full list-none items-center justify-center gap-x-2 py-1">
        {breadcrumbs.map((it, i) => {
          const Comp = it.href ? Link : "span";
          const props = it.href ? { href: it.href } : ({} as LinkProps);

          return (
            <li key={it.slug} className={listElementStyles}>
              <Comp
                {...props}
                className={cn(
                  "uppercase no-underline",
                  i === breadcrumbs.length - 1 && lastLinkStyles
                )}
              >
                {formatBreadcrumb(it.slug)}
              </Comp>
              {i !== breadcrumbs.length - 1 && (
                <Icon className={iconStyles} name="Circle" />
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;

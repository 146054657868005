import { joinPaths } from "@utils/urls/url-utils";
import { Breadcrumb } from "./Breadcrumbs.types";

export const getHomeBreadcrumb = (): Breadcrumb => ({
  slug: "Home",
  href: "/",
});

export const getPathFromUrl = (url: string): string => url.split(/[?#]/)[0];

export const formatTitle = (slug: string) =>
  decodeURI(getPathFromUrl(slug).replaceAll("-", " "));

const buildSegments = (pathname: string | undefined | null) => {
  if (!pathname) return [];
  const queryInd = pathname.indexOf("?");
  const path = queryInd === -1 ? pathname : pathname.substring(0, queryInd);

  return (
    path
      .replace(/\/page\/\d+/, "") // page/{number}
      .replace(/\/success-stories\/industry/, "/success-stories")
      .split("/")
      .slice(1) ?? []
  );
};

export const buildBreadcrumbs = (
  pathname: string | undefined | null
): Breadcrumb[] => {
  const breadcrumbs = buildSegments(pathname)
    .filter((segment) => segment && !segment.startsWith("#"))
    .map((path, i, segments) => ({
      slug: path,
      href: joinPaths(segments.slice(0, i + 1)),
    }));

  return [getHomeBreadcrumb(), ...breadcrumbs];
};
